import React, {useEffect} from 'react';

import configData from '../../../../config.json';
import LandingSignup from './Signup.jsx';

var Landing = () => {

  useEffect(() => {
    orientationCheck();
    if (window.innerWidth >= 551) {
      tileScrollCheck();
    } else {
      tileTapCheck();
    }
  })

  var orientationCheck = () => {
    var reload = () => {
      window.location.reload();
    }

    window.addEventListener('orientationchange', reload);
  }

  var tileScrollCheck = () => {
    var elements;
    var windowHeight;

    var init = () => {
      elements = document.querySelectorAll('#landingTile');
      windowHeight = window.innerHeight;
    }

    var checkPosition = () => {
      for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        var positionFromTop = element.getBoundingClientRect().top;

        if (positionFromTop - windowHeight <= -0.5*windowHeight) {
          element.classList.add('landingTileTransition');
          element.classList.remove('landingTile');
        } else {
          element.classList.add('landingTile');
          element.classList.remove('landingTileTransition');
        }
      }
    }

    window.addEventListener('scroll', checkPosition);
    window.addEventListener('resize', init);

    init();
    checkPosition();
  };

  var tileTapCheck = () => {
    var elements;
    var windowHeight;

    var init = () => {
      elements = document.querySelectorAll('#landingTile');
      windowHeight = window.innerHeight;
    }
    
    var tapEvent = (event) => {
      event.preventDefault();
      for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        var elementTop = element.getBoundingClientRect().top;
        var elementBot = element.getBoundingClientRect().bottom;
        var elementLeft = element.getBoundingClientRect().left;
        var elementRight = element.getBoundingClientRect().right;
        var clickX = event.clientX;
        var clickY = event.clientY;

        if (clickX >= elementLeft && clickX <= elementRight && clickY >= elementTop && clickY <= elementBot) {
          if (element.classList.contains('landingTile')) {
            element.classList.add('landingTileTransition');
            element.classList.remove('landingTile');
          } else if (element.classList.contains('landingTileTransition')) {
            element.classList.add('landingTile');
            element.classList.remove('landingTileTransition');
          }
        } 
      }
    }

    window.addEventListener('click', tapEvent);
    window.addEventListener('resize', init);

    init();
  }

  return (
    <div id='landing'>
      <div id='landingHeader'>
        {/* <h1>{configData.TITLE}</h1>
        <h2>{configData.SUBTITLE}</h2> */}
      </div>
      <div id='landingInstruction'>
        <h2></h2>
        {window.innerWidth > 551
          ? <h2>
              Scroll Down To Learn More
            </h2>
          : <h2>
              Tap Each Tile To Learn More
            </h2>
        }
      </div>
      <div id='landingContent'>
        <div className='landingTile' id='landingTile'>
          <div id='landingTile1'>
          </div>
          <div id='landingTileContent'>
            <h1>{configData.LANDING.TILE1.TITLE}</h1>
            <h2>{configData.LANDING.TILE1.CONTENT}</h2>
          </div>
          {/* <div id='details'>
            <p>Click to learn more</p>
          </div> */}
        </div>
        <div className='landingTile' id='landingTile'>
          <div id='landingTile2'>
          </div>
          <div id='landingTileContent'>
            <h1>{configData.LANDING.TILE2.TITLE}</h1>
            <h2>{configData.LANDING.TILE2.CONTENT}</h2>
          </div>
          {/* <div id='details'>
            <p>Click to learn more</p>
          </div> */}
        </div>
        <div className='landingTile' id='landingTile'>
          <div id='landingTile3'>
          </div>
          <div id='landingTileContent'>
            <h1>{configData.LANDING.TILE3.TITLE}</h1>
            <h2>{configData.LANDING.TILE3.CONTENT}</h2>
          </div>
          {/* <div id='details'>
            <p>Click to learn more</p>
          </div> */}
        </div>
        <div className='landingTile' id='landingTile'>
          <div id='landingTile4'>
          </div>
          <div id='landingTileContent'>
            <h1>{configData.LANDING.TILE4.TITLE}</h1>
            <h2>{configData.LANDING.TILE4.CONTENT}</h2>
          </div>
          {/* <div id='details'>
            <p>Click to learn more</p>
          </div> */}
        </div>
        {/* <div className='landingTile' id='landingTile5'>
          <div id='details'>
          <div id='landingTileContent'>
          <h1>{configData.LANDING.TILE5.TITLE}</h1>
          <h2>{configData.LANDING.TILE5.CONTENT}</h2>
          </div>
          <p>Click for more</p>
          </div>
        </div> */}
      </div>
        <div id='landingInstruction'>
          <h2>Ready to get started? Sign up below.</h2>
        </div>
        <div id='landingSignup'>
          <LandingSignup />
        </div>
    </div>
  )
}

export default Landing;