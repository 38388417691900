import React, {useContext} from 'react';

import Landing from './LandingIndex.jsx';


var App = () => {

  return (
    <div>
      <Landing/>
    </div>
  )

}

export default App;