import React, {useState, useEffect} from 'react';

var Signup = () => {

  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [contactMethod, setContactMethod] = useState('email');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    signupScrollCheck();
  })

  var signupScrollCheck = () => {
    var element;
    var windowHeight;

    var init = () => {
      element = document.querySelectorAll('#signup')[0];
      windowHeight = window.innerHeight;
    }

    var checkPosition = () => {
      var positionFromBot = element.getBoundingClientRect().bottom;

      if (positionFromBot - windowHeight <= 0) {
        element.classList.add('signupTransition');
        element.classList.remove('signup');
      } else {
        element.classList.add('signup');
        element.classList.remove('signupTransition');
      }
      
    }

    window.addEventListener('scroll', checkPosition);
    window.addEventListener('resize', init);

    init();
    checkPosition();
  };
  
/*Create a loading state to have a loading gif overlay between user submit and server response*/

  //check name fields
  var checkName = (firstName, lastName) => {
    if (firstName.length > 0 && lastName.length > 0) {
      return true
    } else {
      if (firstName.length === 0) {
        alert('Please enter your first name');
      }
      if (lastName.length === 0) {
        alert('Please enter your last name');
      }
      return false;
    }
  }
  //check email function
  var validEmail = async (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var unusedEmail = firebase.functions().httpsCallable('unusedEmail');
    if (emailRegex.test(String(email).toLowerCase())) {
      var result = await unusedEmail({email: email});
      if (result.data === true) {
        return true;
      } else {
        alert('Submission failed. Email already in use.')
        return false;
      }
    } else {
      alert ('Please enter a valid email address');
      return false;
    }
  }

  //check phone number function
  var validPhone = async (phone) => {
    var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    var unusedPhone = firebase.functions().httpsCallable('unusedPhone');
    if (phoneRegex.test(phone)) {
      var result = await unusedPhone({phone: phone, contactMethod: contactMethod});
      if (result.data === true) {
        return true;
      } else {
        alert ('Submission failed. Contact method, Phone number combination already in use.')
        return false;
      }
    }
    else {
      alert("Please enter a valid phone number");
      return false;
    }
  }
  
  var leadSignup = async (event) => {
    event.preventDefault();
    setLoading(true);
    var data = {
      firstName: fName,
      lastName: lName,
      email: email,
      phone: phone,
      contactMethod: contactMethod
    }
    if (checkName(fName, lName)) {
      var addLead = firebase.functions().httpsCallable('addLead');
      if (contactMethod === 'email' && await validEmail(email)) {
        addLead(data)
        .then((result) => {
          if (result.data === 'success') {
            window.alert('Your contact information submission was successful. I will reach out via email to schedule by the next business day.')
            setFName('');
            setLName('');
            setEmail('');
            setPhone('');
            setContactMethod('email');
            setLoading(false);
          } else {
            window.alert('Submission unsuccessful. Please try again later.')
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
        })
      } else if (contactMethod !== 'email' && await validPhone(phone)) {
        addLead(data)
        .then((result) => {
          if (result.data === 'success') {
            if (contactMethod === 'text') {
              window.alert('Your contact information submission was successful. I will reach out via text message to schedule by the next business day.')
            } else if (contactMethod === 'call') {
              window.alert('Your contact information submission was successful. I will reach out via phone call to schedule by the next business day.')
            }
            setFName('');
            setLName('');
            setEmail('');
            setPhone('');
            setContactMethod('email');
            setLoading(false);
          } else {
            window.alert('Submission unsuccessful. Please try again later.')
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
        })
      }
    }
    setLoading(false);
    console.log(loading);
  }

  return (
    <div>
      {/* {loading
        ? <div id='loader'></div>
        : null
      } */}
      <div id='signup' className='signup'>
        {loading
          ? <div id='loading'>
              <div id='loader'></div>
            </div>
          : null
        }
        <h1>Assessment and Personalized Session Sign Up</h1>
        <form id='signupContent' onSubmit={leadSignup}>
          <label>First name:<br/>
            <input type='text' id='fname' value={fName} onChange={(e) => setFName(e.target.value)}/><br/>
          </label><br/>
          <label>Last name:<br/>
            <input type='text' id='lname' value={lName} onChange={(e) => setLName(e.target.value)}/><br/>
          </label><br/>
          <label>Email:<br/>
            <input type='text' id='signupEmail' value={email} onChange={(e) => setEmail(e.target.value)}/><br/>
          </label><br/>
          <label>Preferred contact method:<br/>
            <select onChange={(e) => setContactMethod(e.target.value)}><br/>
              <option value='email'>Email</option>
              <option value='text'>Text</option>
              <option value='call'>Call</option>
            </select><br/>
          </label><br/>
          {contactMethod !== 'email'
            ? <div>
                <label>Phone:<br/>
                  <input type='text' id='phone' value={phone} onChange={(e) => setPhone(e.target.value)}/><br/>
                </label><br/>
              </div>

            : null
          }
          {window.innerWidth >= 551 
            ? <input id='signupSubmit' type='submit' value='Submit'/>
            : <button id='signupSubmit' type='submit' onClick={(event) => {leadSignup(event)}}>Submit</button>
          }
        </form>
      </div>
    </div>
  )
}

export default Signup;